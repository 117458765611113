import React from "react"

const Review = () => (
    <div class="site-section bg-light">
  <div class="container">

    <div class="row mb-5 ">
      <div class="col-md-7 text-center mx-auto">
        <span >Klant reviews</span>
        <h2 class="serif">Wat mijn klanten over mij zeggen</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div>
          <h5 class="serif mb-4">Een fotograaf die je speciaal laat voelen</h5>
          <blockquote class="quote_39823">
            <p>
						Op een belangrijke dag zoals je trouwdag wil je mensen om je heen waarbij je je op je gemak voelt. Mensen die jullie het gevoel geven dat jullie speciaal zijn en niet van de vele stellen die gefotografeerd worden. Asim is zonder twijfel van die personen.</p>
          </blockquote>
          <p>&mdash; Reshma & Navin</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
      
        <div>
          <h5 class="serif mb-4">Een spontane fotograaf</h5>
          <blockquote class="quote_39823">
            <p>
						We vonden het contact zowel voor, tijdens als na de bruiloft prettig. Je spontaniteit en enthousiasme sieren je werk. Het eerste gesprek was informatief en we hadden daarna ook geen bedenktijd meer nodig: we wilden jou graag als onze fotograaf! Daar hebben we ook geen spijt van gehad					</p>
          </blockquote>
          <p>&mdash; Saskia & Ashwin</p>
        </div>

      </div>
      <div class="col-lg-4 col-md-6">
        <div>
          <h5 class="serif mb-4">Fotograaf met passie en kwaliteit</h5>
          <blockquote class="quote_39823">
            <p>
						Jou passie voor fotografie is duidelijk naar voren gekomen toen wij onze foto’s zagen. De kwaliteit is oog verbluffend en de professionaliteit ongekend. We zijn ook heel blij dat je met onze foto's een award hebt gewonnen. Bedankt voor de mooie foto's en herinneringen van onze trouwerij.&nbsp; 					</p>
          </blockquote>
          <p>&mdash; Zoheeb en Safia</p>
        </div>
      </div>
    </div>
  </div>
</div>
)

export default Review;